import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import { HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';



@Injectable({
  providedIn: 'root'
})
export class UserService {
  api = "https://noexperience.midupe.pt/";
  //api = "http://127.0.0.1:5000/";
  //api = "http://85.245.204.220:5000/"
  data: string;
  parsed: string;
  
  currentUser: any = null;

  public currentLoginUsername: BehaviorSubject<string> = new BehaviorSubject('Desconhecido');

  constructor(private http: HttpClient, private storage: Storage) {
    this.data = '';
    this.parsed = '';
  }

  public obterVagas(): Observable<object> {
    // Define the data URL
    const dataUrl = this.api + "vagas";
    // Prepare the request
    return this.http.get(dataUrl);
  }

  public obterVagasLogin( email, password) {
    const dataUrl = this.api + 'vagas';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }

  public obterListaCategorias(): Observable<object> {
    const dataUrl = this.api + 'lista-categorias';
    return this.http.get(dataUrl);
  }

  public obterListaEmpresas(): Observable<object> {
    const dataUrl = this.api + 'lista-empresas';
    return this.http.get(dataUrl);
  }

  public obterListaLocais(): Observable<object> {
    const dataUrl = this.api + 'lista-locais';
    return this.http.get(dataUrl);
  }

  public obterVaga(x): Observable<object> {
    const dataUrl = this.api + 'vagas/' + x;
    return this.http.get(dataUrl);
  }

  public obtervagalogin(x, email, password) {
    const dataUrl = this.api + 'vagas/' + x;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }


  public obterFaq(): Observable<object> {
    const dataUrl = this.api + 'faq';
    return this.http.get(dataUrl);
  }

  public obterTipoContratos(): Observable<object> {
    const dataUrl = this.api + 'tipo-contratos';
    return this.http.get(dataUrl);
  }

  public obterTipoTrabalhosRemoto(): Observable<object> {
    const dataUrl = this.api + 'tipo-trabalhos-remotos';
    return this.http.get(dataUrl);
  }

  public obterTipoHorarios(): Observable<object> {
    const dataUrl = this.api + 'tipo-horarios';
    return this.http.get(dataUrl);
  }

  public entrar(email, password) {
    const dataUrl = this.api + 'entrar';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }

  public registar(email, password, name) {
    const dataUrl = this.api + 'registar';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password,
      "name": name
    }

    return this.http.post(dataUrl,data,options);
  }

  public username(email, password) {
    const dataUrl = this.api + 'user';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }

  public search(name, company, category, location, schedule, remote, contract, published, start, limit) {
    const dataUrl = this.api + 'pesquisa?'
    +'name='+name
    +"&company="+ company
    +"&category="+category
    +"&location="+location
    +"&schedule="+schedule
    +"&remote="+remote
    +"&contract="+contract
    +"&published="+published
    +"&start="+start
    +"&limit="+limit;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(dataUrl);;
  }

  public searchLogin(email, password, name, company, category, location, schedule, remote, contract, published, start, limit) {
    const dataUrl = this.api + 'pesquisa?'
    +'name='+name
    +"&company="+company
    +"&category="+category
    +"&location="+location
    +"&schedule="+schedule
    +"&remote="+remote
    +"&contract="+contract
    +"&published="+published
    +"&start="+start
    +"&limit="+limit;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }
    
    return this.http.post(dataUrl, data, options);;
  }

  public saveStorage(email, password){
    this.storage.set('email', email);
    this.storage.set('password', password);
    this.username(email, password).subscribe((username: string) => {
      console.log('save storage entrei');
      this.currentLoginUsername.next(username);
    });
  }

  public async logincurrentgetusername(){
    let email = await this.getEmail()
    let password = await this.getPassword()
    this.username(email, password).subscribe((username: string) => {
      this.currentLoginUsername.next(username);
    });
  }

  public async getEmail(): Promise<string> {
    let email = "";
    await this.storage.get('email').then((val) => {
      if (val) {
        email = String(val);
      }
    });
    return email;
  }

  public async isLogedIn(): Promise<boolean> {
    let email = await this.getEmail();
    if (email == null || email == "") {
      return false;
    } else {
      return true;
    }
  }

  public async getPassword(): Promise<string> {
    let password = "";
    await this.storage.get('password').then((val) => {
      password = String(val);
    });
    return password;
  }

  public clearStorage(){
    this.storage.remove('email');
    this.storage.remove('password');
    this.currentLoginUsername.next('Desconhecido');
  }

  public obterhistorico(email, password) {
    const dataUrl = this.api + 'historico';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }

  public obterguardado(email, password) {
    const dataUrl = this.api + 'guardado';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }

  public verificarguardadologin(x, email, password) {
    const dataUrl = this.api + 'guardado/verificar/' + x;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }

  public verificarguardado(x): Observable<object> {
    const dataUrl = this.api + 'guardado/verificar/' + x;
    return this.http.get(dataUrl);
  }

  public gerirguardadologin(x, email, password) {
    const dataUrl = this.api + 'guardado/gerir/' + x;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };

    let data = {
      "email": email,
      "password": password
    }

    return this.http.post(dataUrl,data,options);
  }

  public reportarvaga(x: string, email: any, reason: any) {
    const dataUrl = this.api + 'vagas/' + x + '/report';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };

    if (reason == "") {
      reason = "Nada."
    }

    let data = {
      "email": email,
      "reason": reason
    }

    return this.http.post(dataUrl, data, options);
  }



}
