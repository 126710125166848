import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import {UserService} from "./api/user.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Início',
      url: '/',
      icon: 'home'
    },
    {
      title: 'Pesquisa',
      url: '/pesquisa',
      icon: 'search'
    },
    {
      title: 'Guardado',
      url: '/guardado',
      icon: 'bookmark'
    },
    {
      title: 'Histórico',
      url: '/historico',
      icon: 'time'
    },
    {
      title: 'FAQ',
      url: '/faq',
      icon: 'chatbubbles'
    },
    {
      title: 'Conta',
      url: '/user',
      icon: 'person'
    },
    {
      title: 'Configurações',
      url: '/configuracoes',
      icon: 'settings'
    }
  ];
  public username = 'Desconhecido'

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public UserService: UserService,
    private router: Router
  ) {
    this.initializeApp();
  }


  ngOnInit() {
    const path = window.location.pathname.split('user')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.UserService.currentLoginUsername.subscribe((currentName: string) => {
      console.log('entrei');
      this.username = currentName;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      this.UserService.logincurrentgetusername();
    });
  }

  ionViewWillEnter() {
    
  }

  logout() {
    this.UserService.clearStorage();
    this.router.navigateByUrl('/');
    
  }
 
}
